<script setup>
import { ref, defineProps, computed, watch, nextTick, onMounted, onActivated } from 'vue'
import skeletonCateLeft from '../skeleton/category-left.vue'
import skeletonCateRight from '../skeleton/category-right.vue'
import SkeletonTab from '../skeleton/header.vue'

import AppCcc from 'public/src/pages/components/ccc/Index.vue'
import CateTitle from './components/CateTitle.vue'
import CateImage from './components/CateImage.vue'
import CateSwiper from './components/CateSwiper.vue'
import tabHeader from './components/header.vue'

import { template, stringifyQueryString } from '@shein/common-function'
import ClientOnly from 'vue-client-only'
import IScroll from 'public/src/pages/components/scroll/index.vue'
import { metricPageSuccess } from 'public/src/pages/common/business-monitor/common.js'
import Monitor from 'public/src/pages/common/home_common/monitor.js'
import HomeSlslog from 'public/src/pages/common/home_common/slslog.js'

const isBrowser = typeof window !== 'undefined'
const UA = isBrowser ? window.navigator.userAgent.toLowerCase() : ''
const isIOS = !!(UA && /iphone|ipad|ipod|ios/.test(UA)) || (isBrowser && window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1)

import { daEventCenter } from 'public/src/services/eventCenter/index'
daEventCenter.addSubscriber({ modulecode: '2-2' })
daEventCenter.addSubscriber({ modulecode: '1-14-1' })

import useVuex from './hooks/useVuex'
import useRouter from './hooks/useRouter'
import useAnalysis from './hooks/useAnalysis'

const { getAnalysisData } = useAnalysis()
const { useMapActions, useMapState } = useVuex('category')
const { changeLeftContent, changeChannel, getLanguage } = useMapActions(['getBffNavList', 'changeLeftContent', 'changeChannel', 'getLanguage'])
const { router, route } = useRouter() 

const { bffCat, bffLanguages } = useMapState(['bffCat', 'bffLanguages'])

const props = defineProps({
  cateType: {
    type: String,
    default: ''
  },
  freeshippingContent: {
    type: Array,
    default: () => ([])
  },
  freeshippingContext: {
    type: Object,
    default: () => ({})
  },
  freeshippingSceneData: {
    type: Object,
    default: () => ({})
  },
  isPreview: {
    type: Boolean,
    default: false
  },
  isShowFreeshipping: {
    type: Boolean,
    default: false
  },
  shadowBeforeIndex: {
    type: Number,
    default: -1
  },
  shadowAfterIndex: {
    type: Number,
    default: -1
  },
  shadowAfterHeight: {
    type: String,
    default: '0'
  },
  shadowAnimate: {
    type: Boolean,
    default: false
  },
  handleTouchStart: {
    type: Function,
    default: () => { }
  },
  translateYChange: {
    type: Function,
    default: () => { }
  },
  nextPagePrefix: {
    type: String,
    default: ''
  },
  rightScrollbars: {
    type: Boolean,
    default: false
  },
  layoutTop: {
    type: Number,
    default: 0
  },
})


const topNav = computed(() => bffCat.value?.topNav)
const isOneCate = computed(() => topNav.value?.length <= 1 ) // 是否只有一个一级分类
const oneCategory = computed(() => bffCat.value?.oneCategory)
const twoCategory = computed(() => bffCat?.value?.twoCategory || [])
const tabLoadingStatus = computed(() => bffCat.value?.tabLoadingStatus)
const oneCateLoadingStatus = computed(() => bffCat.value?.oneCateLoadingStatus)
const twoCateLoadingStatus = computed(() => bffCat.value?.twoCateLoadingStatus)
const active = computed(() => bffCat.value?.activeChannel)
const activeLeftContent = computed(() => oneCategory?.value?.contents?.[active?.value?.oneCate] || {})
const language = computed(() => bffLanguages.value)
const nextOneCateNameNew = computed(() => oneCategory.value?.contents?.[Number(active?.value?.oneCate) + 1]?.firstFloorContent?.props?.metaData?.categoryLanguage)
const iScrollLeft = ref(null)
const iScrollRight = ref(null)
const canNextPage = ref(null)
const isActive = ref(true)

onMounted(() => {
  // 服务端不调用
  // getLanguage()
  handleSlslog()
})
onActivated(() => {
  iosRepaint()
})
watch(() => twoCategory.value, (newVal) => {
  if (newVal) {
    nextTick(() => {
      handleLayout()
    })
  }
})
watch(() => activeLeftContent.value, () => {
  const style = oneCategory?.value?.style?.navStyleInfoList
  const value = style?.find(item => item.confFieldName === 'ScrolldownType') || {}
  canNextPage.value = oneCategory.value?.contents?.[Number(active?.value?.oneCate) + 1] && value.confValue == 'ON'
}, {
  immediate: true
})
// 顶部tab内容为空上报日志
const handleSlslog = () => {
  if(!topNav?.value?.length) {
    HomeSlslog.slsCompFilter({
      logLevel: 3,
      tag: 'web_client_home_error_total',
      message: 'CategoryDataError',
      reason: 'tabEmpty',
      module: 'TopBar',
    }, false)
  }
}
// 切换一级分类
const oneCategorySelectedHandler = async ({ onCateIndex,  nextPage }) => {
  await changeLeftContent({
    oneCate: onCateIndex,
    cateType: props?.cateType
  })
  Monitor.metricCategoryClick({
    tags: { 
      page: 'page_category',
      cate_level: '1',
    },
    message: 'Left level first category click'
  })
  changeRouterQuery()
  // handleLayout({ nextPage, isChangeOneCate: true, isChangeChannel: false })
}

// 处理头部tab切换
const handleHeaderTabSelected = async (data) => {
  await changeChannel({
    channel: data.item,
    channelIndex: data.itemIndex,
    cateType: props?.cateType
  })
  // 更改query参数
  changeRouterQuery()
}
const changeRouterQuery = () => {
  let newQuery = Object.assign({}, route.query, {
    channelId: active?.value?.channel?.id,
    oneCate: active?.value?.oneCate,
  })
  router.replace(`${route.path}?${stringifyQueryString({ queryObj: newQuery })}`)
}
/* 过渡动画 */ 
const handleShadowAnimate = () => {
  iScrollLeft.value?.updateHeight()
  const beforeElement = active?.value?.oneCate > 0 ? iScrollLeft?.value.$el.querySelector(`.cate-new-left`).children[active?.value?.oneCate - 1] : {}
  const y = beforeElement.offsetTop || 0
  iScrollLeft?.value?.scrollTo({ y, time: 300 })
}
/* 切换tab进行计算高度 */
const handleLayout = ({ isChangeChannel = true, isChangeOneCate, nextPage } = {}) => {
  window.iScrollRight = iScrollRight
  if (!iScrollLeft.value) return
  if (nextPage) {
    handleShadowAnimate()
    iScrollRight?.value?.refresh?.(true)
    return
  }
  if (isChangeChannel) {
    iScrollLeft?.value?.refresh?.(true)
    iScrollRight?.value?.refresh?.(true)
    handleShadowAnimate()
  } else if (isChangeOneCate) {
    iScrollRight?.value?.refresh?.(true)
  }
}
// 处理翻页
const handleBoundary = async ({ direction }) => {
  if (!canNextPage.value) return
  if (direction !== 'ttb') return
  const onCateIndex = Number(active?.value?.oneCate) + 1
  oneCategorySelectedHandler({ onCateIndex, nextPage: true })
  // 触发一级点击埋点
  daEventCenter.triggerNotice({
    daId: '1-14-1-21',
    bindData: {
      index: onCateIndex,
      item: oneCategory.value?.contents?.[onCateIndex],
      channel: { ...active?.value?.channel, index: active?.value?.channelIndex },
      nextPage: true
    }
  }),
  daEventCenter.triggerNotice({
    daId: '1-14-1-24',
    bindData: {
      channel: {
        ...active?.value?.channel,
        index: active?.value?.channelIndex
      },
      oneCate: active?.value?.oneCate,
      oneCateContent: activeLeftContent.value
    }
  })
}
const iosRepaint = () => {
  if (isIOS) {
    // 更改定位方式，强制重绘
    isActive.value = false
    nextTick(() => {
      requestAnimationFrame(() => {
        isActive.value = true
      })
    })
  }
}
</script>

<template>
  <div
    class="c-new-category c-new-category__content--ccc j-new-category j-c-new-category__content--ccc"
    :class="{'zIndex': !isActive}"
    :style="{ top: layoutTop + 'rem' }"
  >
    <tab-header
      v-if="!isOneCate && !tabLoadingStatus && !isPreview"
      ref="header"
      :type="cateType"
      :tab-data="topNav"
      :active-key="active?.channel?.id"
      @selectedHandler="handleHeaderTabSelected"
    />
    <ClientOnly>
      <skeleton-tab v-if="tabLoadingStatus" />
    </ClientOnly>

    <!-- 免邮政策组件 -->
    <div
      v-if="freeshippingContent && freeshippingContent.length"
      class="scrollfix-sub"
    >
      <AppCcc
        :content="freeshippingContent"
        :context="freeshippingContext"
        :scene-data="freeshippingSceneData"
      />
    </div>
  
    <IScroll
      ref="iScrollLeft"
      class="cate-new-left-wrap j-cate-new-left-wrap"
      :class="{'one-cate': isOneCate || isPreview, 'has-freeshipping': isShowFreeshipping}"
      :origin="true"
      @touchstart="handleTouchStart"
    >
      <ul 
        class="cate-new-left"
        :class="{'is-img-label': false}"
      >
        <li
          v-for="(cate, index) in oneCategory?.contents "
          :key="index + cate.id || 1"
          v-expose="getAnalysisData('1-14-1-20', { delayReady: 300, index, item: cate, channel: { ...active?.channel, index: active.channelIndex} }, 'cat_oneCategory')"
          v-tap="getAnalysisData('1-14-1-21', {index, item: cate, channel: { ...active?.channel, index: active.channelIndex} })"
          :class="{
            'cate-new-left__father-first-title': false,
            'has-img': false,
            'activeshow': active.oneCate == index
          }"
          @click="oneCategorySelectedHandler({clickFirstLevel: true, onCateIndex: index}, cate)"
        >
          <a
            data-rec-mark="-"
            class="j-cate-one"
            :class="[
              `j-cate-one-${index}`,
              shadowBeforeIndex === index ? 'cate-one__shadow-before': '',
              shadowAnimate ? 'cate-one__shadow-animate' : '',
              active.oneCate == index ? 'j-sa-active-one' : '',
            ]"
            :style="cate.color ? {color: cate?.color} : ''"
            href="javascript:;"
            tabindex="0"
            :aria-label="cate?.firstFloorContent && cate?.firstFloorContent?.props?.metaData?.categoryLanguage"
          >
            <span>{{ cate?.firstFloorContent && cate.firstFloorContent?.props?.metaData?.categoryLanguage }}</span>
            <span
              v-if="shadowAfterIndex === index"
              class="cate-one__shadow-after"
              :style="`height: ${shadowAfterHeight}`"
            ></span>
          </a>
        </li>
      </ul>
    </IScroll>
    <ClientOnly>
      <skeleton-cate-left 
        v-if="oneCateLoadingStatus" 
        :one-cate="isOneCate" 
      />
    </ClientOnly>
    <IScroll
      ref="iScrollRight"
      class="cate-new-right-wrap j-cate-new-right-wrap"
      :class="{'one-cate': isOneCate || isPreview, 'has-freeshipping': isShowFreeshipping, 'zIndex': !isActive}"
      :scrollbars="rightScrollbars"
      :load-more-distance="80"
      :boundary-distance="50"
      :willChange="true"
      @touchstart="handleTouchStart"
      @onBoundary="handleBoundary"
    >
      <ul 
        class="cate-new-right j-cate-new-right" 
        :class="{'has-next-page': canNextPage}"
        style="will-change: transform; transform: translate3d(0, 0, 0);"
      >
        <li
          v-for="(cate, index) in twoCategory?.contents || []"
          :key="`${cate.cateId}_${active.channel}_${active.oneCate}_${index}`"
          class="cate-entire-line"
          :class="{
            'border-bottom': ['FLOOR_COMPONENT'].includes(cate.styleType),
          }"
          style="will-change: transform; transform: translate3d(0, 0, 0);"
        >
          <CateTitle
            v-if="['FLOOR_COMPONENT'].includes(cate.styleType)"
            ref="cateTitle"
            :cate-type="cateType"
            :cate="cate"
            :activeChannel="active"
            :activeLeftContent="activeLeftContent"
            :comp-index="index"
            style="will-change: transform; transform: translate3d(0, 0, 0);"
          />
          <CateImage
            v-if="['ONE_IMAGE_COMPONENT', 'TWO_IMAGE_COMPONENT'].includes(cate.styleType)"
            :cate="cate"
            :cate-type="cateType"
            :activeChannel="active"
            :activeLeftContent="activeLeftContent"
            :comp-index="index"
            style="will-change: transform; transform: translate3d(0, 0, 0);"
          />
          <CateSwiper
            v-if="['IMAGE_CAROUSEL_COMPONENT'].includes(cate.styleType) && cate.props.items.length > 0"
            :cate="cate"
            :cate-type="cateType"
            :activeChannel="active"
            :activeLeftContent="activeLeftContent"
            :comp-index="index"
            :scene-data="{}"
            :index="index + 1"
            swiper-inject-style=".swiper-pagination .swiper-pagination-bullet-active{
                width: 10px;
                height: 4px;
                background: rgba(255, 255, 255, 0.8);
                border: 1px solid #bbb;
                border-radius: 6px;
              }"
            style="will-change: transform; transform: translate3d(0, 0, 0);"
          />
        </li>
        <div
          v-if="canNextPage"
          v-expose="{
            id: '1-14-1-25',
            prefix: nextPagePrefix,
            delayReady: 2000,
            data: {
              channel: {
                ...active?.channel,
                index: active?.channelIndex
              },
              oneCate: active?.oneCate,
              oneCateContent: activeLeftContent
            },
          }"
          class="cate-next-page"
        >
          <p>
            {{ template(nextOneCateNameNew, language?.SHEIN_KEY_PWA_17501) }}
          </p>
          <i class="iconfont icon-circle-r"></i>
        </div>
      </ul>
    </IScroll>
    <ClientOnly>
      <skeleton-cate-right 
        v-if="twoCateLoadingStatus" 
        :handle-layout="handleLayout" 
        :one-cate="isOneCate" 
      />
    </ClientOnly>
  </div>
</template>
<style lang="less" scoped>
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.cate-new-left-wrap {
  position: absolute;
  top: 1.1733rem;
  bottom: 0;
  width: 2.7733rem;
  overflow-y: hidden;
  background: #f6f6f6;
  &.one-cate {
    top: 0;
  }
  &.has-freeshipping {
    top: 2.1799rem; // tab:1.1733rem + freeshipping:1rem
  }
  &.one-cate.has-freeshipping {
    top: 1rem;
  }
  .cate-new-left {
    > li {
      &.activeshow {
        background-color: @sui_color_brand;
        span {
          color: #fff;
          font-weight: bold;
        }
        .icon-down {
          /*rw:begin*/
          color: #fff;
        }
      }
    }
    a {
      position: relative;
      display: block;
      padding: .2667rem .08rem .2667rem .1867rem;
      line-height: 1.2083;
      display: flex;
      align-items: center;
      span {
        display: block;
        width: 100%;
        margin: 0 auto;
        font-size: 12px;
        color: #222;
        word-break: break-word;
        .line-camp(3);
      }
    }
    &.is-img-label {
      li {
        opacity: 0.54;
        &.has-img {
          a {
            height: auto;
          }
        }
        &.activeshow {
          background-color: #fff;
          opacity: 1;
          span {
            color: #222;
          }
          &.has-img {
            span {
              font-weight: normal;
            }
          }
        }
      }
      a {
        min-height: 1.63rem;
        span {
          margin-top: 0.12rem;
          line-height: 0.4rem;
          text-align: center;
        }
      }
      .icon {
        width: 0.64rem;
        height: 0.64rem;
        display: block;
        margin: 0 auto 0.19rem auto;
      }
    }
    &__father-first-title {
      position: relative;
      a {
        padding-right: 0.7467rem;
      }
      .icon-down {
        position: absolute;
        right: 0.2135rem;
        top: 50%;
        color: #959595;
        transform: translateY(-50%);
        transition: transform 0.2s;
        font-size: 12px;
      }
      .cate-new-left__child-expanded-icon {
        transform: translateY(-50%) rotate(180deg);
      }
    }
    &__expand-li {
      position: relative;
      padding: 0.3467rem 0.4267rem;
      color: #767676;
      &_active {
        font-weight: bold;
        color: @sui_color_brand;
        &:before {
          content: '';
          width: 0.0534rem;
          position: absolute;
          left: 0.2rem;
          top: 50%;
          transform: translateY(-50%);
          height: 0.45rem;
          background-color: @sui_color_brand;
        }
      }
    }
    &__expand-txt {
      transform: scale(0.8333);
      word-break: break-word;
      .line-camp(2);
    }
  }
}
.cate-new-right-wrap {
  position: absolute;
  top: 1.1733rem;
  bottom: 0;
  width: 7.2267rem;
  .margin-l(2.7733rem);
  overflow-y: hidden;
  background-color: #fff;
  &.one-cate {
    top: 0;
  }
  &.has-freeshipping {
    top: 2.1799rem; // tab:1.1733rem + freeshipping:1rem
  }
  &.one-cate.has-freeshipping {
    top: 1rem;
  }
}
.cate-new-right {
  position: relative;
  overflow: hidden;
  padding: 0 0.32rem 0.32rem;
  width: 6.5867rem;
  min-height: 100%;
  box-sizing: content-box;
  &.has-next-page {
    padding-bottom: 2rem;
  }
  .cate-entire-line {
    .fl();
    width: 100%;
    min-height: 0.855rem;
    text-align: center;
    font-size: 0;
    &:first-child {
      // margin-top: 0.32rem;
      .c-cate__entire-banner {
        padding: 0 0 0.19rem 0;
      }
    }
    &:last-child {
      border-bottom: none;
    }
  }
  .border-bottom {
    border-bottom: 0.04rem solid #e5e5e5;
  }
}
.cate-next-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 2rem;
  position: absolute;
  bottom: 0;
  left: 0.73rem /*rtl:ignore*/;
  right: 0.73rem /*rtl:ignore*/;
  color: #cccccc;
  text-align: center;
  p {
    line-height: 1.1667;
    margin-bottom: 0.08rem;
  }
  [class*='iconfont'] {
    display: inline-block;
    transform: rotate(-90deg);
    .font-dpr(34px);
  }
}
</style>
