import { setCookie, getCookie } from '@shein/common-function'
import { getCookieKey } from './utils'

let cookieValue
export const branchCookie = () => {
  const branchCookieKey = getCookieKey('branch')
  
  if (!cookieValue) {
    cookieValue = getCookie(branchCookieKey)
  }
  
  return {
    cookieValue,
    branchCookieKey
  }
}

export function setBranchCookie({ key, value, end }) {
  cookieValue = value
  setCookie({ key, value, end })
}
