<script lang="jsx">
export default {
  name: 'SkeletonRight',
  functional: true,
  props: {
    oneCate: {
      type: Boolean,
      default: false,
    },
  },
  render(_, { props = {} }) {
    // <div class='c-skeleton__right-slider'></div>
    return (
      <ul
        class={[props.oneCate ? 'c-skeleton__right_onecate' : '', 'c-skeleton__right']}
      >
   
        {Array.from({ length: 2 }).map((item, index) => {
          return (
            <div style={ index === 0 ? { 'padding-top': '0.32rem' } : null }>
              <div class='c-skeleton__right-title'></div>
              <ul class='c-skeleton__right-classify-container'>
                {Array.from({ length: 9 }).map(() => (
                  <li class='c-skeleton__right-classify'>
                    <div class='c-skeleton__right-classify-img'></div>
                    <div class='c-skeleton__right-classify-name'></div>
                  </li>
                ))}
              </ul>
            </div>
          )
        })}
      </ul>
    )
  },
}
</script>

<style lang="less">
.c-skeleton {
  &__right {
    position: absolute;
    top: 1.1733rem;
    bottom: 0;
    width: 7.2267rem;
    margin-left: 2.7733rem;
    padding: 0 0.32rem 0.32rem;
    overflow-y: hidden;
    background: #fff;
    &_onecate {
      top: 0;
    }
  }

  // &__right-slider {
  //   min-width: 6.5867rem;
  //   min-height: 2.85522rem;
  //   margin-top: 0.32rem;
  //   background: #f5f5f5;
  // }

  &__right-title {
    min-height: 0.32rem;
    background: #f5f5f5;
    margin: 0.465rem 4rem 0.465rem 0;
  }

  &__right-classify-container {
    display: flex;
    justify-content: flex-start;
    justify-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 0 0 0;
    margin: 0 -0.1067rem;
    background: #fff;
  }

  &__right-classify {
    width: 2.05rem;
    height: 2.5061rem;
    padding: 0;
    margin: 0 0.1067rem 0.4rem;
  }

  &__right-classify-img {
    width: 1.76rem;
    height: 1.76rem;
    background: #f5f5f5;
    border-radius: 50%;
  }

  &__right-classify-name {
    width: 1.76rem;
    height: 0.34665rem;
    margin-top: 0.0533rem;
    background: #f5f5f5;
  }
}
</style>
