<template>
  <div>
    <IScroll
      ref="iScrollLeft"
      class="cate-new-left-wrap j-cate-new-left-wrap"
      :class="{'one-cate': isOneCate || isPreview, 'has-freeshipping': isShowFreeshipping}"
      @touchstart="handeTouchStart"
      @translateYChange="translateYChange"
    >
      <ul 
        class="cate-new-left"
        :class="{'is-img-label': false}"
      >
        <template v-for="(cate, index) in oneCategory ">
          <li
            v-if="cate"
            :key="index + cate.id || 1"
            :class="{
              'cate-new-left__father-first-title': false,
              'has-img': false,
              'activeshow': active.oneCate == index
            }"
            @click="oneCategorySelectedHandler({clickFirstLevel: true, oneCategoryIndex: index}, cate)"
          >
            <a
              :data-report-tag="cateType + '-' + active.channel + '-' + index"
              :data-index="Number(generateOneCateIndex(cate.id)) + 1"
              :data-name="cate.firstFloorContent && cate.firstFloorContent.props && (cate.firstFloorContent.props.metaData.categoryName || cate.firstFloorContent.props.metaData.categoryLanguage)"
              :data-nav-node-id="cate.firstFloorContent && cate.firstFloorContent.props.id"
              :data-category-id="cate.firstFloorContent && cate.firstFloorContent.props.metaData.categoryId"
              data-rec-mark="-"
              class="j-cate-one"
              :class="[
                `j-cate-one-${index}`,
                shadowBeforeIndex === index ? 'cate-one__shadow-before': '',
                shadowAnimate ? 'cate-one__shadow-animate' : '',
                active.oneCate == index ? 'j-sa-active-one' : '',
              ]"
              :style="cate.color ? {color: cate.color} : ''"
              href="javascript:;"
              tabindex="0"
              :aria-label="cate.firstFloorContent && cate.firstFloorContent.props.metaData.categoryLanguage"
            >
              <span>{{ cate.firstFloorContent && cate.firstFloorContent.props.metaData.categoryLanguage }}</span>
              <span
                v-if="shadowAfterIndex === index"
                class="cate-one__shadow-after"
                :style="`height: ${shadowAfterHeight}`"
              ></span>
            </a>
          </li>
        </template>
      </ul>
    </IScroll>
    <ClientOnly>
      <skeleton-cate-left 
        v-if="cat.oneCateLoadingStatus" 
        :one-cate="isOneCate" 
      />
    </ClientOnly>
    <IScroll
      ref="iScrollRight"
      class="cate-new-right-wrap j-cate-new-right-wrap"
      :class="{'one-cate': isOneCate || isPreview, 'has-freeshipping': isShowFreeshipping}"
      :scrollbars="rightScrollbars"
      :load-more-distance="80"
      :boundary-distance="50"
      :willChange="true"
      @touchstart="handeTouchStart"
      @onBoundary="handleBoundary"
      @translateYChange="translateYChange"
    >
      <ul 
        class="cate-new-right j-cate-new-right" 
        :class="{'has-next-page': canNextPage}"
      >
        <template v-for="(cate, index) in twoCategory">
          <cate-new-item 
            v-if="cate && cate.fsFlag"
            :key="`${cate.cateId}_${active.channel}_${active.oneCate}_${index}`"
            :cate="cate"
            :index="index"
            :active="active"
            :cateLinks="cateLinks"
            :activeParentCatePosition="activeParentCatePosition"
            :activeChildCatePosition="activeChildCatePosition"
            :isChildCate="isChildCate"
            :toggleExpand="toggleExpand"
            :isFirstScreen="true"
          />
          <ClientOnly
            v-if="cate && !cate.fsFlag"
            :key="`${cate.cateId}_${active.channel}_${active.oneCate}_${index}`"
          >
            <cate-new-item
              :key="`${cate.cateId}_${active.channel}_${active.oneCate}_${index}`"
              :cate="cate"
              :index="index"
              :active="active"
              :cateLinks="cateLinks"
              :activeParentCatePosition="activeParentCatePosition"
              :activeChildCatePosition="activeChildCatePosition"
              :isChildCate="isChildCate"
              :toggleExpand="toggleExpand"
              :isFirstScreen="false"
            />
          </ClientOnly>
        </template>
        <div
          v-if="canNextPage"
          v-expose="{
            id: '1-14-1-10',
            prefix: nextPagePrefix,
            delayReady: 2000,
            data: {
              channelActiveClass: '.j-sa-active-topcategory',
              oneCateActiveClass: '.j-sa-active-one',
            },
          }"
          class="cate-next-page"
        >
          <p>
            {{ template(nextOneCateNameNew, language.SHEIN_KEY_PWA_17501) }}
          </p>
          <i class="iconfont icon-circle-r"></i>
        </div>
      </ul>
    </IScroll>

    <ClientOnly>
      <skeleton-cate-right 
        v-if="cat.twoCateLoadingStatus" 
        :handle-layout="handleLayout" 
        :one-cate="isOneCate" 
      />
    </clientonly>
  </div>
</template>

<script>
import skeletonCateLeft from './skeleton/category-left.vue'
import skeletonCateRight from './skeleton/category-right.vue'
import ClientOnly from 'vue-client-only'
import IScroll from 'public/src/pages/components/scroll/index.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
const daEventExpose = daEventCenter.getExposeInstance()
import cateMixin from './mixin/ccc-cate-mixin'
import CateNewItem from './components/cate-new-item.vue'
import HomeSlslog from 'public/src/pages/common/home_common/slslog.js'

export default {
  components: {
    IScroll,
    ClientOnly,
    skeletonCateLeft,
    skeletonCateRight,
    CateNewItem,
  },
  mixins: [cateMixin],
  props: {
    dynamicCates: {
      type: Array,
      default: () => []
    },
    handeTouchStart: {
      type: Function,
      default: () => { }
    },
    translateYChange: {
      type: Function,
      default: () => { }
    },
    analysis: {
      type: Object,
      default: () => ({})
    },
    toggleExpand: {
      type: Function,
      default: () => { }
    },
    rightScrollbars: {
      type: Boolean,
      default: false
    },
    shadowAfterIndex: {
      type: Number,
      default: undefined
    },
    shadowAnimate: {
      type: Boolean,
      default: false
    },
    shadowAfterHeight: {
      type: String,
      default: 'auto'
    },
    shadowBeforeIndex: {
      type: Number,
      default: undefined
    },
    nextPagePrefix: {
      type: String,
      default: ''
    },
    isShowFreeshipping: {
      type: Boolean,
      default: false
    },
    cateType: {
      type: String,
      default: 'cat'
    },
    isPreview: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      canScrolldownType: '', // 能否下拉自动翻页
    }
  },
  computed: {
    canNextPage() {
      return !!this.oneCategory?.[this.active.oneCate + 1] && this.showScrollDownType 
    },
    nextOneCateNameNew() {
      return this.oneCategory && this.oneCategory[this.active.oneCate + 1] && this.oneCategory[this.active.oneCate + 1]?.firstFloorContent?.props?.metaData?.categoryLanguage || ''
    },
    showScrollDownType() {
      const value = this.style?.find(item => item.confFieldName === 'ScrolldownType') || {}
      return value.confValue == 'ON'
    },
  },
  watch: {
    oneCategory: {
      handler(newVal) {
        if(!newVal.length) {
          this.handleSlslog('LeftBar', 'tabEmpty')
        }
      },
      immediate: true,
    },
    twoCategory: {
      handler(newVal,) {
        try{
          const topBarName = this.categoryData[this.active.channel]?.channelName || ''
          const leftBarName = this.oneCategory[this.active.oneCate]?.firstFloorContent?.props?.metaData?.categoryLanguage || ''
          if(!newVal.length) {
            // 会先置空再赋值, 导致暂不支持值为空时告警功能
            // this.handleSlslog('RightNode', 'NodeEmpty', topBarName, leftBarName)
          } else {
            newVal.map(el => {
              const nodes = el?.child?.[0]?.props?.items || []
              const itemName = el.props?.metaData?.categoryName || ''
              nodes.map(node => {
                if(!node.categoryName) {
                  this.handleSlslog('RightNode', 'textEmpty', topBarName, leftBarName, itemName)
                }
                if(!node?.cover?.src) {
                  this.handleSlslog('RightNode', 'imageError', topBarName, leftBarName, itemName)
                }
              })
            })
          }
        } catch(error) {
          console.error(error)
        }
      },
      immediate: true,
    },
  },
  methods: {
    /* 过渡动画 */ 
    handleShadowAnimate() {
      this.$refs.iScrollLeft.updateHeight()
      const beforeElement = this.active.oneCate > 0 ? this.$refs.iScrollLeft.$el.querySelector(`.cate-new-left`).children[this.active.oneCate - 1] : {}
      const y = beforeElement.offsetTop || 0
      this.$refs.iScrollLeft.scrollTo({ y, time: 300 })
    },
    /* 切换tab进行计算高度 */  // VUE 不支持ref 转发，所以子组件需要维护一份自己的ref
    handleLayout ({ isChangeChannel = true, isChangeOneCate, nextPage } = {}) {
      if (!this.$refs.iScrollLeft) return
      daEventExpose.resetAll?.(this.nextPagePrefix)
      if (nextPage) {
        this.handleShadowAnimate()
        this.$refs.iScrollRight?.refresh?.(true)
        return
      }
      if (isChangeChannel) {
        this.$refs.iScrollLeft?.refresh?.(true)
        this.$refs.iScrollRight?.refresh?.(true)
        this.handleShadowAnimate()
      } else if (isChangeOneCate) {
        this.$refs.iScrollRight?.refresh?.(true)
      }
    },
    // 处理翻页
    async handleBoundary({ direction }) {
      if (!this.canNextPage) return
      if (direction !== 'ttb') return
      const oneCategoryIndex = this.active.oneCate + 1
      await this.oneCategorySelectedHandler({ oneCategoryIndex, nextPage: true })
      daEventCenter.triggerNotice({
        daId: '1-14-1-9',
        extraData: {
          channelActiveClass: '.j-sa-active-topcategory',
          oneCateActiveClass: '.j-sa-active-one', // 报上一个左侧类目
        }
      })
    },
    handleSlslog(module, reason, topBarName = '', leftBarName = '', itemName = '') {
      HomeSlslog.slsCompFilter({
        logLevel: 3,
        tag: 'web_client_home_error_total',
        message: 'CategoryDataError',
        reason,
        module,
        topBarName,
        leftBarName,
        itemName
      }, false)
    }
  },
}
</script>
<style lang="less" scoped>
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.cate-new-left-wrap {
  position: absolute;
  top: 1.1733rem;
  bottom: 0;
  width: 2.7733rem;
  overflow-y: hidden;
  background: #f6f6f6;
  &.one-cate {
    top: 0;
  }
  &.has-freeshipping {
    top: 2.1799rem; // tab:1.1733rem + freeshipping:1rem
  }
  &.one-cate.has-freeshipping {
    top: 1rem;
  }
  .cate-new-left {
    > li {
      &.activeshow {
        background-color: @sui_color_brand;
        span {
          color: #fff;
          font-weight: bold;
        }
        .icon-down {
          /*rw:begin*/
          color: #fff;
        }
      }
    }
    a {
      position: relative;
      display: block;
      padding: .2667rem .08rem .2667rem .1867rem;
      line-height: 1.2083;
      display: flex;
      align-items: center;
      span {
        display: block;
        width: 100%;
        margin: 0 auto;
        font-size: 12px;
        color: #222;
        word-break: break-word;
        .line-camp(3);
      }
    }
    &.is-img-label {
      li {
        opacity: 0.54;
        &.has-img {
          a {
            height: auto;
          }
        }
        &.activeshow {
          background-color: #fff;
          opacity: 1;
          span {
            color: #222;
          }
          &.has-img {
            span {
              font-weight: normal;
            }
          }
        }
      }
      a {
        min-height: 1.63rem;
        span {
          margin-top: 0.12rem;
          line-height: 0.4rem;
          text-align: center;
        }
      }
      .icon {
        width: 0.64rem;
        height: 0.64rem;
        display: block;
        margin: 0 auto 0.19rem auto;
      }
    }
    &__father-first-title {
      position: relative;
      a {
        padding-right: 0.7467rem;
      }
      .icon-down {
        position: absolute;
        right: 0.2135rem;
        top: 50%;
        color: #959595;
        transform: translateY(-50%);
        transition: transform 0.2s;
        font-size: 12px;
      }
      .cate-new-left__child-expanded-icon {
        transform: translateY(-50%) rotate(180deg);
      }
    }
    &__expand-li {
      position: relative;
      padding: 0.3467rem 0.4267rem;
      color: #767676;
      &_active {
        font-weight: bold;
        color: @sui_color_brand;
        &:before {
          content: '';
          width: 0.0534rem;
          position: absolute;
          left: 0.2rem;
          top: 50%;
          transform: translateY(-50%);
          height: 0.45rem;
          background-color: @sui_color_brand;
        }
      }
    }
    &__expand-txt {
      transform: scale(0.8333);
      word-break: break-word;
      .line-camp(2);
    }
  }
}
.cate-new-right-wrap {
  position: absolute;
  top: 1.1733rem;
  bottom: 0;
  width: 7.2267rem;
  .margin-l(2.7733rem);
  overflow-y: hidden;
  background-color: #fff;
  &.one-cate {
    top: 0;
  }
  &.has-freeshipping {
    top: 2.1799rem; // tab:1.1733rem + freeshipping:1rem
  }
  &.one-cate.has-freeshipping {
    top: 1rem;
  }
}
.cate-new-right {
  position: relative;
  overflow: hidden;
  padding: 0 0.32rem 0.32rem;
  width: 6.5867rem;
  min-height: 100%;
  box-sizing: content-box;
  &.has-next-page {
    padding-bottom: 2rem;
  }
  .cate-entire-line {
    .fl();
    width: 100%;
    min-height: 0.855rem;
    text-align: center;
    font-size: 0;
    &:first-child {
      // margin-top: 0.32rem;
      .c-cate__entire-banner {
        padding: 0 0 0.19rem 0;
      }
    }
    &:last-child {
      border-bottom: none;
    }
  }
  .border-bottom {
    border-bottom: 0.04rem solid #e5e5e5;
  }
}
.cate-next-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 2rem;
  position: absolute;
  bottom: 0;
  left: 0.73rem /*rtl:ignore*/;
  right: 0.73rem /*rtl:ignore*/;
  color: #cccccc;
  text-align: center;
  p {
    line-height: 1.1667;
    margin-bottom: 0.08rem;
  }
  [class*='iconfont'] {
    display: inline-block;
    transform: rotate(-90deg);
    .font-dpr(34px);
  }
}
</style>
