<script lang="jsx">
export default {
  name: 'SkeletonLeft',
  functional: true,
  props: {
    oneCate: {
      type: Boolean,
      default: false,
    },
  },
  render: function (h, { props = {} }) {
    const { oneCate } = props
    return (
      <ul
        class={[oneCate ? 'c-skeleton__left_onecate' : '', 'c-skeleton__left']}
      >
        {Array.from({ length: 20 }).map(() => (
          <li class='c-skeleton__left-item'></li>
        ))}
      </ul>
    )
  },
}
</script>

<style lang="less">
.c-skeleton {
  &__left {
    position: absolute;
    top: 1.1733rem;
    bottom: 0;
    width: 2.7733rem;
    overflow-y: hidden;
    background: #fff;
    &_onecate {
      top: 0;
    }
  }

  &__left-item {
    margin: 0.3rem 0.21333rem;
    height: 0.813rem;
    background: #f5f5f5;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
  }
}
</style>
