<template>
  <li
    class="cate-entire-line"
    :class="{
      'border-bottom': ['FLOOR_COMPONENT'].includes(cate.styleType),
    }"
    style="transform: translate3d(0,0,0)"
  >
    <cate-new-title
      v-if="['FLOOR_COMPONENT'].includes(cate.styleType)"
      ref="cateTitle"
      :cate="cate"
      :active="active"
      :cate-links="cateLinks"
      :active-parent-cate-position="activeParentCatePosition"
      :active-child-cate-position="activeChildCatePosition"
      :is-child-cate="isChildCate"
      @toggleSubCatExpand="toggleExpand"
    />
    <cate-new-image
      v-if="['ONE_IMAGE_COMPONENT', 'TWO_IMAGE_COMPONENT'].includes(cate.styleType)"
      :cate="cate"
      :active="active"
      :cate-links="cateLinks"
      :active-parent-cate-position="activeParentCatePosition"
      :active-child-cate-position="activeChildCatePosition"
      :is-child-cate="isChildCate"
      :index="index+1"
    />
    <cate-new-swiper
      v-if="['IMAGE_CAROUSEL_COMPONENT'].includes(cate.styleType)"
      :cate="cate"
      :active="active"
      :cate-links="cateLinks"
      :active-parent-cate-position="activeParentCatePosition"
      :active-child-cate-position="activeChildCatePosition"
      :is-child-cate="isChildCate"
      :scene-data="{}"
      :index="index + 1"
      swiper-inject-style=".swiper-pagination .swiper-pagination-bullet-active{
                width: 10px;
                height: 4px;
                background: rgba(255, 255, 255, 0.8);
                border: 1px solid #bbb;
                border-radius: 6px;
              }"
    />
  </li>
</template>
<script>
import CateNewTitle from './cate-new-title.vue'
import CateNewImage from './cate-new-image.vue'
import CateNewSwiper from './cate-new-swiper.vue'
export default {
  name: 'CateNewItem',
  components: {
    CateNewTitle,
    CateNewImage,
    CateNewSwiper
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    cate: {
      type: Object,
      default: () => ({})
    },
    active: {
      type: Object,
      default: () => ({})
    },
    cateLinks: {
      type: Object,
      default: () => ({})
    },
    activeParentCatePosition: {
      type: Number,
      default: 0,
    },
    activeChildCatePosition: {
      type: Number,
      default: 0,
    },
    isChildCate: {
      type: Boolean,
      default: false,
    },
    toggleExpand: {
      type: Function,
      default: () => { }
    },
    isFirstScreen: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      isVisible: this.isFirstScreen ? true : false,
      observer: null
    }
  },
}
</script>
